import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: "root",
})
export class ApiService {
  public base_url = "https://api.pqrs.gpsi.com.co";
  public url = "https://api.pqrs.gpsi.com.co/api";
  public uploads_url = "";
  static  upload;

  constructor(private http: HttpClient) {
    if(!environment.production){
      this.url = "http://pqrs/api";
      this.base_url = "http://pqrs";
    }
    this.uploads_url = this.base_url + "/uploads/";
   
  }


  cambiarCiclo(data) {
    return this.http.post(`${this.url}/suscriptor/cambiar_ciclo`, data);
  }
  dashboard() {
    return this.http.get(`${this.url}/pqrs/dashboard`);
  }
  atributos() {
    return this.http.get(`${this.url}/pqrs/atributos`);
  }
  rangos() {
    return this.http.get(`${this.url}/pqrs/rangos`);
  }
  reportes() {
    return this.http.get(`${this.url}/reportes`);
  }

  factura(data) {
    return this.http.post(`${this.url}/pqrs/factura`, data);
  }

  suscriptores(potenciales = false) {
    if(!potenciales){
      return this.http.get(`${this.url}/suscriptor`);
    } else {
      return this.http.get(`${this.url}/suscriptor/potenciales`);
    }
    
  }
  suscriptor(id) {
    return this.http.get(`${this.url}/suscriptor/${id}`);
  }

  guardarSuscriptorPotencial(data){
    return this.http.post(`${this.url}/suscriptor/potencial`, data);
  }

  orden(id) {
    return this.http.get(`${this.url}/orden/${id}`);
  }


  servicios() {
    return this.http.get(`${this.url}/servicio`);
  }

  tramites() {
    return this.http.get(`${this.url}/tramite`);
  }

  causales() {
    return this.http.get(`${this.url}/causal`);
  }

  trabajos() {
    return this.http.get(`${this.url}/trabajo`);
  }

  funcionarios() {
    return this.http.get(`${this.url}/funcionario`);
  }

  saveFuncionario(data) {
    return this.http.post(`${this.url}/funcionario`, data);
  }

  deleteFuncionario(id) {
    return this.http.delete(`${this.url}/funcionario/${id}`);
  }

  resultados() {
    return this.http.get(`${this.url}/resultado`);
  }

  pqrs(id) {
    return this.http.get(`${this.url}/pqrs/${id}`);
  }

  empresa(id) {
    return this.http.get(`${this.url}/empresa/${id}`);
  }
  
  allPqrs() {
    return this.http.get(`${this.url}/pqrs`);
  }

  pqrsSuscriptor(id) {
    return this.http.get(`${this.url}/pqrs/suscriptor/${id}`);
  }

  pqrsHabilitada(data) {
    return this.http.post(`${this.url}/pqrs/habilitada`, data);
  }

  editarSolucion(data, id) {
    return this.http.put(`${this.url}/solucion/${id}`, data);
  }
  savePqrs(data) {
    return this.http.post(`${this.url}/pqrs`, data);
  }

  accion(id){
    return this.http.get(`${this.url}/accion/${id}`);
  }
  saveAction(data) {
    return this.http.post(`${this.url}/accion`, data);
  }
  updateAction(data, id) {
    return this.http.put(`${this.url}/accion/${id}`, data);
  }

  saveOrder(data) {
    return this.http.post(`${this.url}/orden`, data);
  }

  saveExecution(data) {
    return this.http.post(`${this.url}/ejecucion`, data);
  }
  ordenes(){
    return this.http.get(`${this.url}/orden`);

  }
  ordenesFuncionario(){
    return this.http.get(`${this.url}/orden/funcionario`);

  }
  ordenesSuscriptor(id){
    return this.http.get(`${this.url}/orden/suscriptor/${id}`);
  }

  ordenesAccion(id){
    return this.http.get(`${this.url}/orden/accion/${id}`);
  }

  ejecucionOrden(id){
    return this.http.get(`${this.url}/ejecucion/orden/${id}`);
  }

  pqrsActions(id: any): any {
    return this.http.get(`${this.url}/accion/pqrs/${id}`);
  }

  solucionAccion(id: any): any {
    return this.http.get(`${this.url}/solucion/accion/${id}`);
  }

  ultimaSolucion(){
    return this.http.get(`${this.url}/solucion/ultima`);
  }

  nextRadicado() {
    return this.http.get(`${this.url}/pqrs/next_radicado`);
  }
  
  nextOrdenId() {
    return this.http.get(`${this.url}/orden/next_id`);
  }
  saveSolucion(data){
    return this.http.post(`${this.url}/solucion`, data);
  }

  login(email,password){
    return this.http.post(`${this.url}/login`,  {email:email,password:password});
  }

  /*
  Reportes
  */

  reporte_ot(id){
    return this.http.get(`${this.url}/pqrs/reporte_ot_pdf/${id}`, { responseType: 'blob' })
  }

  reporte_pqrs(id){
    return this.http.get(`${this.url}/pqrs/reporte_pqrs_pdf/${id}`, { responseType: 'blob' })
  }

  reporte_solucion(id){
    return this.http.get(`${this.url}/pqrs/reporte_solucion_pdf/${id}`, { responseType: 'blob' })
  }

  informe_pqrs(datos,tipo){
    return this.http.post(`${this.url}/pqrs/informe_pqrs_${tipo}`,datos, { responseType: 'blob' })
  }

  resumen_pqrs(datos,tipo){
    return this.http.post(`${this.url}/pqrs/resumen_pqrs_${tipo}`,datos, { responseType: 'blob' })
  }
  
  resumen_ordenes(datos,tipo){
    return this.http.post(`${this.url}/pqrs/resumen_ordenes_${tipo}`,datos, { responseType: 'blob' })
  }

  informe_ordenes(datos,tipo){
    return this.http.post(`${this.url}/pqrs/informe_ordenes_${tipo}`,datos, { responseType: 'blob' })
  }

  sui(datos){
    return this.http.post(`${this.url}/pqrs/sui`,datos, { responseType: 'blob' })
  }
  sui_excel(datos){
    return this.http.post(`${this.url}/pqrs/sui_excel`,datos, { responseType: 'blob' })
  }
  eliminarArchivo(datos){
      return this.http.post(`${this.url}/pqrs/eliminar_archivo`,datos)
  }
}
