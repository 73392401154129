import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private apiService: ApiService) { }

  fill(data, text){

    const keys = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const value = data[key];
      text = text.replace(new RegExp('{{'+key+'}}','g'), value);
    }
    return text;
  }

  actualizarSuscriptorActivo(id){
    localStorage.removeItem("suscriptor_k"+this.suscriptorActivo())
    localStorage.setItem("suscriptor",id);
  }
  actualizarInfoSuscriptor(datos){
    localStorage.setItem("infosuscriptor",datos);
  }
  async infoSuscriptor(){

    let result = await new Promise((resolve, reject) => {
        if(!localStorage.getItem("suscriptor_k"+this.suscriptorActivo())){
          this.apiService.suscriptor(this.suscriptorActivo()).subscribe(res => {
            localStorage.setItem("suscriptor_k"+res["id"],JSON.stringify(res))
            resolve(res)
          })
        } else {
          let s = localStorage.getItem("suscriptor_k"+this.suscriptorActivo())
          resolve(JSON.parse(s))
        }
    })
    return result;

  }
  suscriptorActivo(){
    return localStorage.getItem("suscriptor")
  }

}
