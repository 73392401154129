import { Component } from "@angular/core";


import { L10n, setCulture } from "@syncfusion/ej2-base";


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
  ) {
    this.initializeApp();
  }

  initializeApp() {
    
  }
  ngOnInit() {
    L10n.load({
      "es-ES": {
        grid: {
          EmptyRecord: "No hay registros",
          GroupDropArea: "Arrastre una columna para agrupar",
          autoFitAll: "Ajuste automático de todas las columnas",
          autoFit: "Ajuste automatico de esta columna",
          Group: "Agrupar sobre esta columna",
          Ungroup: "Desagrupar sobre esta columna",
          SortAscending: "Ordenar ascendente",
          SortDescending: "Ordenar descendente",
          Columnchooser: "Mostrar/Ocultar columnas",
          FilterMenu: "Filtrado",
          FilterButton: "Filtrar",
          ClearButton: "Limpiar",
          StartsWith: "Empieza con...",
          EndsWith: "Termina con...",
          Contains: "Contiene",
          Equal: "Igual a",
          NotEqual: "Diferente de",
          LessThan: "Menor que",
          LessThanOrEqual: "Menor o igual que",
          GreaterThan: "Mayor que",
          GreaterThanOrEqual: "Mayor o igual que",
          EnterValue: "Introduzca un valor",
          Excelexport: "Exportar",
          Add: "Nuevo registro",
          Update: "Guardar",
          Cancel: "Cancelar",
          EditOperationAlert: "Seleccione un registro para editar",
          DeleteOperationAlert: "Seleccione un registro para dar de baja",
          Search: "Busqueda",
          Edit: "Editar",
          Delete: "Baja",
          ClearFilter: "Limpiar filtro",
          UnGroup: "Haga clic aqui para desagrupar",
          SelectAll: "Seleccionar todo",
          CancelButton: "Cancelar",
          TextFilter: "Criterio de filtrado",
          Item: "Equipo",
          Items: "Equipos",
        },
        pager: {
          FirstPage: "Primera página",
          LastPage: "Ultima página",
          PreviousPage: "Página anterior",
          NextPage: "Página siguiente",
          currentPageInfo: "Página {0} de {1}",
          totalItemsInfo: "({0} registros)",
          previousPageTooltip: "Página anterior",
          nextPageTooltip: "Página siguiente",
          previousPagerTooltip: "Página anterior",
          nextPagerTooltip: "Página siguiente",
          firstPageTooltip: "Ir a la primera página",
          lastPageTooltip: "Ir a la última pagina",
        },
        uploader: {
          invalidMinFileSize:
            "La taille du fichier est trop petite! S'il vous plaît télécharger des fichiers avec une taille minimale de 10 Ko",
          invalidMaxFileSize: "La taille du fichier dépasse 28 Mo",
          invalidFileType: "Le type de fichier n'est pas autorisé",
          "Browse": "Explorar",
          Clear: "Limpiar",
          Upload: "Subir",
          dropFilesHint: "ou Déposer des fichiers ici",
          uploadFailedMessage: "Impossible d'importer le fichier",
          uploadSuccessMessage: "Archivos cargados.",
          removedSuccessMessage: "Archivos cargados exitosamente.",
          removedFailedMessage: "Le fichier n'a pas pu être supprimé",
          inProgress: "Cargando...",
          readyToUploadMessage: "Listo para subir.",
          remove: "Eliminar",
          cancel: "Cancelar",
          delete: "Eliminar",
        },
      },
    });
    setCulture("es-ES");
  }
}
