import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { TipoGuard } from './guards/tipo.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard,TipoGuard]
  },

  {
    path: 'potenciales',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard,TipoGuard]
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'ot',
    loadChildren: () => import('./ot/ot.module').then( m => m.OtPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'funcionarios',
    loadChildren: () => import('./funcionario/funcionario.module').then( m => m.FuncionarioPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'pqrs',
    loadChildren: () => import('./pqrs/pqrs.module').then( m => m.PqrsPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'suscriptor',
    loadChildren: () => import('./suscriber/suscriber.module').then( m => m.SuscriberPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accion',
    loadChildren: () => import('./action/action.module').then( m => m.ActionPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'solucion',
    loadChildren: () => import('./solution/solution.module').then( m => m.SolutionPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then( m => m.ListPageModule),
    canActivate: [AuthGuard,TipoGuard]
  },
  {
    path: 'ordenes',
    loadChildren: () => import('./ot-general-list/ot-general-list.module').then( m => m.OtGeneralListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'informes',
    loadChildren: () => import('./informes/informes.module').then( m => m.InformesPageModule),
    canActivate: [AuthGuard]
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
