import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class EventsService {
  private data = new Subject<any>();
  constructor() {}

  publish(data: any) {
    this.data.next(data);
  }

  listen(): Subject<any> {
    return this.data;
  }
}
